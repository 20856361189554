import {useAppSelector} from "../../store";
import {getMergedTransactionsMonths} from "../../store/rocketData";
import {TISOMonth} from "../../6-shared/types";
import {envelopeModel, TEnvNode, TGroupNode} from "../../5-entities/envelope";
import {useCallback} from "react";


export type TemplatedGroup = {
    id: string; // Уникальный идентификатор группы
    type: "formula" | "template_group" | 'template_sub_group'; // Тип группы: формула или обычная
    title: string; // Название группы
    parent: string | null; // Родительская группа, если есть
    sub_group: TemplatedGroup[]; // Вложенные подгруппы
    values: TemplatedValues[]; // Рассчитанные значения для группы
    values_source: TEnvNode[]; // Источники значений для группы
    editable_values: boolean; // Можно ли редактировать значения группы
    editable_source: boolean; // Можно ли редактировать источники значений группы
    formula?: string; // Формула для вычисления значений, если это формульная группа
    calculate_totals?: boolean; // Флаг для вычисления суммы дочерних групп и собственных значений/конвертов
};

export type TemplatedValues = {
    date: TISOMonth;
    totalActivity: Record<string, number> | number;
};

const useTableDefaultTemplate = () => {

    const findGroupById: (id: string, structure: TemplatedGroup[]) => (TemplatedGroup) | undefined = useCallback((id: string, structure: TemplatedGroup[]) => {
        for (let group of structure) {
            if (group.id === id) {
                return group;
            } else if (group.sub_group?.length) {
                // @ts-ignore
                const found = findGroupById(id, group.sub_group);
                if (found) return found;
            }
        }
    }, [])


    const defaultPLTemplateStructure: TemplatedGroup[] = [
        {
            id: "other_metrics",
            type: "template_group",
            title: "Доп.метрики",
            parent: null,
            sub_group: [
                {
                    id: "other_metrics_self",
                    type: "template_sub_group",
                    title: "Доп.метрики (основная)",
                    parent: "other_metrics",
                    sub_group: [],
                    values: [],
                    values_source: [],
                    editable_values: false,
                    editable_source: true,
                    formula: undefined
                },
                {
                    id: "hidden",
                    type: "template_sub_group",
                    title: "Скрытая",
                    parent: "other_metrics",
                    sub_group: [],
                    values: [],
                    values_source: [],
                    editable_values: false,
                    editable_source: true,
                    formula: undefined
                },
            ],
            values: [],
            values_source: [],
            editable_values: false,
            editable_source: true,
            calculate_totals: true
        },
        {
            id: "revenue",
            type: "template_group",
            title: "Выручка",
            parent: null,
            sub_group: [],
            values: [],
            values_source: [],
            editable_values: true,
            editable_source: false,
        },
        {
            id: "cost_price",
            type: "template_group",
            title: "Себестоимость",
            parent: null,
            sub_group: [
                {
                    id: "cost_price_self",
                    type: "template_sub_group",
                    title: "Себестоимость(основная)",
                    parent: "cost_price",
                    sub_group: [],
                    values: [],
                    values_source: [],
                    editable_values: true,
                    editable_source: false,
                },
                {
                    id: "operating_costs",
                    type: "template_sub_group",
                    title: "Операционные затраты",
                    parent: "cost_price",
                    sub_group: [],
                    values: [],
                    values_source: [],
                    editable_values: true,
                    editable_source: false,
                }
            ],
            values: [],
            values_source: [],
            editable_values: true,
            editable_source: false,
            calculate_totals: true
        },
        {
            id: "gross_profit",
            type: "formula",
            title: "Валовая прибыль",
            parent: null,
            sub_group: [
                {
                    id: "gross_profit_share",
                    type: "formula",
                    title: "% валовой прибыли",
                    parent: 'gross_profit',
                    sub_group: [],
                    values: [],
                    values_source: [],
                    editable_values: false,
                    editable_source: false,
                    formula: "gross_profit / revenue",
                },
            ],
            values: [],
            values_source: [],
            editable_values: false,
            editable_source: false,
            formula: "revenue - cost_price",
        },
        {
            id: "expenses",
            type: "template_group",
            title: "Расходы",
            parent: null,
            values: [],
            values_source: [],
            editable_values: false,
            editable_source: true,
            formula: undefined,
            calculate_totals: true,
            sub_group: [
                {
                    id: "variable_expenses",
                    type: "template_sub_group",
                    title: "Переменные расходы",
                    parent: "expenses",
                    sub_group: [],
                    values: [],
                    values_source: [],
                    editable_values: false,
                    editable_source: true,
                },
                {
                    id: "fixed_expenses",
                    type: "template_sub_group",
                    title: "Постоянные расходы",
                    parent: "expenses",
                    sub_group: [],
                    values: [],
                    values_source: [],
                    editable_values: false,
                    editable_source: true,
                }
            ],
        },
        {
            id: "ebitda",
            type: "formula",
            title: "Операционная прибыль (EBITDA)",
            parent: null,
            sub_group: [
                {
                    id: "ebitda_share",
                    type: "formula",
                    title: "% EBITDA",
                    parent: "ebitda",
                    sub_group: [],
                    values: [],
                    values_source: [],
                    editable_values: false,
                    editable_source: false,
                    formula: "ebitda / gross_profit",
                },
            ],
            values: [],
            values_source: [],
            editable_values: false,
            editable_source: false,
            formula: "gross_profit + expenses",
        },
        {
            id: "other_expenses",
            type: "template_group",
            title: "Прочие расходы",
            parent: null,
            sub_group: [],
            values: [],
            values_source: [],
            editable_values: false,
            editable_source: true,
        },
        {
            id: "net_profit",
            type: "formula",
            title: "Чистая прибыль",
            parent: null,
            sub_group: [
                {
                    id: "net_profit_share",
                    type: "formula",
                    title: "% Чистой прибыли",
                    parent: "net_profit",
                    sub_group: [],
                    values: [],
                    values_source: [],
                    editable_values: false,
                    editable_source: false,
                    formula: "net_profit / revenue",
                },
            ],
            values: [],
            values_source: [],
            editable_values: false,
            editable_source: false,
            formula: "ebitda - other_expenses",
        },
        {
            id: "outside_operating_expenses",
            type: "template_group",
            title: "Вне операционные расходы",
            parent: null,
            sub_group: [],
            values: [],
            values_source: [],
            editable_values: false,
            editable_source: true,
        },
        {
            id: "retained earnings",
            type: "formula",
            title: "Нераспределенная прибыль",
            parent: null,
            sub_group: [],
            values: [],
            values_source: [],
            editable_values: false,
            editable_source: true,
            formula: 'net_profit + outside_operating_expenses',
        },

    ]
    return {defaultPLTemplateStructure, findGroupById}
}


export default useTableDefaultTemplate
