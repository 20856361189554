import React, {FC, memo, useCallback, useEffect, useState} from 'react'
import {Paper} from '@mui/material'
import {TISOMonth, TTransaction} from '6-shared/types'
import {useToggle} from '6-shared/hooks/useToggle'

import {TEnvelopeId} from '5-entities/envelope'
import {Header} from './Header'
import {toISOMonth} from "../../../6-shared/helpers/date";
import {balances} from "5-entities/envBalances";
import useTableDefaultTemplate, {TemplatedGroup} from "../table-template";
import {EditableGroup} from "./EditableGroup";
import {FormulaGroup} from "./FormulaGroup";
import {EnvelopeGroup} from "./EnvelopeGroup";
import {shallowEqual} from "react-redux";
import {useEnvelopeGroup} from "./useEnvelopeGroup";
import {usePnLMonthsHandler} from "./usePnlReportData";
import {useEditableGroup} from "./useEditableGroup";
import {useFormulaGroup} from "./useFormulaGroup";
import {
    getPL2Data,
    savePL2Data,
    updatePL2EditableStructure,
    updatePL2EnvelopeStructure,
    updatePL2ExpandedRows,
    updatePL2Months
} from "../../../6-shared/api/rocketData/pl2ApiMethods";
import {useAppSelector} from "../../../store";
import {getUserId} from "../../../5-entities/user/model";

type TagTableProps = {
    className?: string
    onOpenDetails: (id: string) => void
    onShowTransactions: (list: TTransaction[]) => void
    onMonthNameClick: (name: string) => void
    onGroupClick: (group: any) => void
}

const PnlReportTable: FC<TagTableProps> = props => {
    const {
        className,
        onOpenDetails,
        onShowTransactions,
        onMonthNameClick,
        onGroupClick
    } = props

    const [showAll, toggleShowAll] = useToggle()
    const [reorderMode, toggleReorderMode] = useToggle()

    const onShowExactTransactions = useCallback(
        (list: TTransaction[]) => {
            onShowTransactions(list)
        },
        [onShowTransactions]
    )

    const monthListZero = balances.useMonthList()
//@ts-ignore
    const handleMonthNameClick = (month) => {
        const whichMonth = monthListZero.includes(month) ? month : toISOMonth(new Date())
        onMonthNameClick(whichMonth)
    }

    interface RenderFormulaAndEditGroupsProps {
        group: TemplatedGroup;
        onNameClick: (template_group: TemplatedGroup) => void;
        reorderMode: boolean;
    }

    interface RenderEditableGroupsProps extends RenderFormulaAndEditGroupsProps {
        getGroupValues: (id: string) => any[];
        onValueChange: (id: string, date: TISOMonth, value: number) => void;
    }

    const [expandedRows, setExpandedRows] = useState<string[]>([])
    const handleExpand = useCallback(async (id: string, isExpandable: boolean) => {
        const rows =
            isExpandable ? [...expandedRows, id] : expandedRows.filter((rowId) => rowId !== id)
        await updatePL2ExpandedRows(userId, rows)
        setExpandedRows(rows)
    }, [expandedRows])

    const RenderEditableGroups: FC<RenderEditableGroupsProps> = React.memo(({
                                                                                group,
                                                                                onNameClick,
                                                                                reorderMode,
                                                                                getGroupValues,
                                                                                onValueChange
                                                                            }) => {
        const isExpanded = expandedRows.includes(group.id);
        return (
            <EditableGroup
                group={group}
                key={`editable-${group.id}`}
                isEditMode={reorderMode}
                onGroupNameClick={() => onNameClick(group)}
                onValueChange={onValueChange}
                getGroupValues={getGroupValues}
                toggleExpand={handleExpand}
                isExpanded={isExpanded}
            >
                {/*// @ts-ignore*/}
                {!!group.sub_group && group.sub_group.length > 0 && group.sub_group.map((sub) => (
                    <EditableGroup
                        group={sub}
                        key={`editable-${sub.id}`}
                        isSubgroup={true}
                        isEditMode={reorderMode}
                        onGroupNameClick={() => onNameClick(sub)}
                        onValueChange={onValueChange}
                        getGroupValues={getGroupValues}
                        toggleExpand={handleExpand}
                        isExpanded={isExpanded}
                    />
                ))}
            </EditableGroup>
        );
    })

    interface RenderFormulaGroupsProps extends RenderFormulaAndEditGroupsProps {
        // @ts-ignore
        getFormulaValues: (id: string) => Record<date, any>[];
    }

    const RenderFormulaGroups: FC<RenderFormulaGroupsProps> = React.memo(({
                                                                              group,
                                                                              onNameClick,
                                                                              reorderMode,
                                                                              getFormulaValues
                                                                          }) => {
        const isExpanded = expandedRows.includes(group.id);
        return (
            <FormulaGroup
                group={group}
                key={`formula-${group.id}`}
                onGroupNameClick={() => onNameClick(group)}
                // @ts-ignore
                getGroupValues={getFormulaValues}
                toggleExpand={handleExpand}
                isExpanded={isExpanded}
                isEditMode={reorderMode}>
                {/*// @ts-ignore*/}
                {!!group.sub_group && group.sub_group.length > 0 && group.sub_group.map((sub) => (
                    <FormulaGroup
                        key={`formula-${sub.id}`}
                        group={sub}
                        isSubgroup={true}
                        isEditMode={reorderMode}
                        onGroupNameClick={() => onNameClick(sub)}
                        toggleExpand={handleExpand}
                        isExpanded={isExpanded}
                        // @ts-ignore
                        getGroupValues={getFormulaValues}
                    />
                ))}
            </FormulaGroup>
        );
    });


    const {
        allMonths,
        onMonthsChange,
        months
    } = usePnLMonthsHandler();
    const {defaultPLTemplateStructure} = useTableDefaultTemplate();

    const {
        getEnvelopeById,
        getGroupTotal,
        moveEnvelope,
        currConvert,
        getEnvTransactionsRocketById,
        getEnvelopeGroupStructure,
        groupTotals,
        envGroupsStructure,
        restoreEnvGroups,
        initializeEnvGroupsStructure
        // @ts-ignore
    } = useEnvelopeGroup(months);
    const {
        setValue,
        getGroupValues,
        getAllGroupValues,
        editableGroupsStructure,
        restoreEditableGroups
        // @ts-ignore
    } = useEditableGroup(months);
    // @ts-ignore
    const {getCalculatedResults} = useFormulaGroup(months, getAllGroupValues, groupTotals)


    const saveDefaultValues = async (userId: number) => {
        try {

            const payload = {

                editable_structure: [],
                envelope_structure: [],
                months: months
            }

            // @ts-ignore
            await savePL2Data(userId, payload);
        } catch (error) {
            console.error("Error saving default values:", error);
        }
    };

    const handleRestore = (
        editableGroupsStructure: TemplatedGroup[],
        envelopeGroupsStructure: TemplatedGroup[],
        months: TISOMonth[],
        expandedRows: string[]
    ) => {

        !!envelopeGroupsStructure.length
            ? restoreEnvGroups(envelopeGroupsStructure)
            : initializeEnvGroupsStructure()

        if (!!editableGroupsStructure.length) {
            restoreEditableGroups(editableGroupsStructure)
        }
        if (!!months.length) {
            onMonthsChange(months)
        }

        if (!!expandedRows.length) {
            setExpandedRows(expandedRows)
        }
    }


    const userId = useAppSelector(getUserId)
    //load backup data
    useEffect(() => {
        const fetchData = async () => {
            try {
                const backup = await getPL2Data(userId);
                if (backup) handleRestore(backup.editable_structure, backup.envelope_structure, backup.months, backup.expanded_rows);

            } catch (error) {
                await saveDefaultValues(userId);
                console.error("Error fetching backup data:", error);
            }
        };

        fetchData();
    }, [userId]);


    const handleGroupClick = (group: any) => {
        onGroupClick(group)
    }


    const handleMoveEnvelope = async (envelopeId: TEnvelopeId, fromGroupId: string, toGroupId: string) => {
        moveEnvelope(envelopeId, fromGroupId, toGroupId)
        await updatePL2EnvelopeStructure(userId, envGroupsStructure)
    }

    const handleSetValue = async (groupId: string, date: TISOMonth, value: number) => {
        setValue(groupId, date, value)
        await updatePL2EditableStructure(userId, editableGroupsStructure)
    }

    const handleMonthChange = async (months: TISOMonth[]) => {
        onMonthsChange(months)
        // @ts-ignore
        await updatePL2Months(userId, months)
    }


    // const isSmall = useIsSmall()

    return (
        <Paper className={className} elevation={0} sx={{
            position: 'relative', pb: 1,
            // width: `${months.length * 100 + (isSmall ? 200 : 300)}px`
        }}>

            <>
                <Header
                    isAllShown={showAll}
                    isReordering={reorderMode}
                    onShowAllToggle={toggleShowAll}
                    onReorderModeToggle={toggleReorderMode}
                    onMonthNameClick={handleMonthNameClick}
                    months={months}
                    allMonths={allMonths}
                    //@ts-ignore
                    onMonthsChange={handleMonthChange}
                />
                {defaultPLTemplateStructure.map((group, groupIdx) => {
                        if (!!group.formula) {
                            return <RenderFormulaGroups
                                group={group}
                                reorderMode={reorderMode}
                                onNameClick={(group) => handleGroupClick(group)}
                                getFormulaValues={getCalculatedResults}
                            />
                        }
                        if (group.editable_values) {
                            return <RenderEditableGroups
                                group={group}
                                onNameClick={(group) => handleGroupClick(group)}
                                getGroupValues={getGroupValues}
                                onValueChange={handleSetValue}
                                reorderMode={reorderMode}
                            />
                        }

                        if (group.editable_source) {
                            return (
                                <EnvelopeGroup
                                    // @ts-ignore
                                    getEnvelopeGroupStructure={getEnvelopeGroupStructure}
                                    groupId={group.id}
                                    isEditMode={reorderMode}
                                    key={group.id || groupIdx}
                                    onGroupNameClick={handleGroupClick}
                                    openTransactionsPopover={onShowExactTransactions}
                                    getEnvelopeById={getEnvelopeById}
                                    getGroupTotal={getGroupTotal}
                                    currConvert={currConvert}
                                    moveEnvelope={handleMoveEnvelope}
                                    onOpenDetails={onOpenDetails}
                                    getEnvTransactionsRocketById={getEnvTransactionsRocketById}
                                    toggleExpandGroup={handleExpand}
                                    expandedRows={expandedRows}
                                />
                            );
                        }
                    }
                )
                }
            </>
        </Paper>
    )
}

export const PnlReport = memo(
    (props: TagTableProps) => {

        return <PnlReportTable {...props} />
    }, shallowEqual
);



