import React, {FC} from "react";
import {TemplatedGroup} from "../table-template";
import {Group} from "./Group";
import {TEnvelopeId, TEnvNode} from "../../../5-entities/envelope";
import {Row} from "./Row";
import {Parent} from "./Parent";
import {useExpandEnvelopes} from "./models/useExpandEnvelopes";
import {TISOMonth} from "../../../6-shared/types";
import {areEqual} from "react-window";
import {shallowEqual} from "react-redux";

type TEnvelopeGroupProps = {

    groupId: string
    getEnvelopeGroupStructure: (id: string) => TemplatedGroup
    isEditMode: boolean
    onGroupNameClick: (group: TemplatedGroup) => void
    openTransactionsPopover: (ids: any) => void

    getEnvTransactionsRocketById: (id: string) => Record<string, any>
    getEnvelopeById: (id: string) => any
    onOpenDetails: (id: string) => void
    currConvert: (value: any, month: TISOMonth) => any
    moveEnvelope: (envelopeId: TEnvelopeId, fromGroupId: string, toGroupId: string) => void

    getGroupTotal?: (id: string) => Array<{ date: TISOMonth, totalActivity: number }>

    expandedRows?: string[]
    toggleExpandGroup?: (id: TEnvelopeId, isExpanded: boolean) => void

}


type TEnvelopeGroupRowProps = {
    envelopes: TEnvNode[]
    isEditMode: boolean
    openTransactionsPopover: (ids: any) => void
    onExpandToggle: (id: TEnvelopeId) => void
    expanded: TEnvelopeId[]
    groupID: string
    getTransactionsById: (id: string) => any
    getEnvelopeById: (id: string) => any
    currConvert: (value: any, month: TISOMonth) => any
    moveEnvelope: (envelopeId: TEnvelopeId, fromGroupId: string, toGroupId: string) => void
    onOpenDetails: (id: string) => void
}

const RenderEnvelopes: FC<TEnvelopeGroupRowProps> = React.memo( ({
                                                         envelopes,
                                                         isEditMode,
                                                         groupID,
                                                         getTransactionsById,
                                                         getEnvelopeById,
                                                         currConvert,
                                                         moveEnvelope,
                                                         onExpandToggle,
                                                         openTransactionsPopover,
                                                         expanded,
                                                         onOpenDetails
                                                     }) => {


    return envelopes.map(parent => {


        let renderChildren = parent.children
            .map((child, idx: number, arr: string | any[]) => (
                <Row
                    key={'child' + child.id}
                    id={child.id}
                    isLastVisibleChild={idx === arr.length - 1}
                    isDefaultVisible={true}
                    isEditMode={isEditMode}
                    openTransactionsPopover={openTransactionsPopover}
                    openDetails={onOpenDetails}
                    moveEnvelope={moveEnvelope}
                    templateGroupId={groupID}
                    getTransactionsByEnvID={getTransactionsById}
                    getEnvelopeById={getEnvelopeById}
                    currConvert={currConvert}
                />
            ))

        if (!!parent.children.length) {
            renderChildren = [
                <Row
                    isSelf
                    key={'self_env' + parent.id}
                    id={parent.id}
                    isEditMode={isEditMode}
                    isDefaultVisible={true}
                    openTransactionsPopover={openTransactionsPopover}
                    openDetails={onOpenDetails}
                    moveEnvelope={moveEnvelope}
                    templateGroupId={groupID}
                    getTransactionsByEnvID={getTransactionsById}
                    getEnvelopeById={getEnvelopeById}
                    currConvert={currConvert}
                />,
                ...renderChildren,
            ]
        }

        const isExpanded =
            !!renderChildren.length && expanded.includes(parent.id)
        // !!renderChildren.length

        return (
            <Parent
                key={'parent_env' + parent.id}
                id={parent.id}
                isExpanded={isExpanded}
                onExpandToggle={onExpandToggle}
                parent={
                    <Row
                        key={'child_env' + parent.id}
                        id={parent.id}
                        isDefaultVisible={true}
                        isExpanded={isExpanded}
                        onExpandToggle={onExpandToggle}
                        isEditMode={isEditMode}
                        openDetails={onOpenDetails}
                        openTransactionsPopover={openTransactionsPopover}
                        moveEnvelope={moveEnvelope}
                        templateGroupId={groupID}
                        getTransactionsByEnvID={getTransactionsById}
                        getEnvelopeById={getEnvelopeById}
                        currConvert={currConvert}
                    />
                }
                children={renderChildren}
            />
        )
    })
},shallowEqual)


export const EnvelopeGroup: FC<TEnvelopeGroupProps> = React.memo(({
                                                                      groupId,
                                                                      getEnvelopeGroupStructure,
                                                                      isEditMode,
                                                                      onGroupNameClick,
                                                                      openTransactionsPopover,
                                                                      getEnvTransactionsRocketById,
                                                                      getEnvelopeById,
                                                                      getGroupTotal,
                                                                      currConvert,
                                                                      moveEnvelope,
                                                                      onOpenDetails,
                                                                      expandedRows,
                                                                      toggleExpandGroup
                                                                  }) => {
    const {expanded, toggle} = useExpandEnvelopes()

    const group = getEnvelopeGroupStructure(groupId)

    const isExpandedGroup = expandedRows?.includes(groupId) ?? true
    return (
        <Group
            id={group.id}
            groupName={group.title}
            key={`envelopes-${group.id}`}
            // @ts-ignore
            getGroupTotals={getGroupTotal}
            onGroupNameClick={() => onGroupNameClick(group)}
            isEditMode={isEditMode}
            // @ts-ignore
            toggleExpand={toggleExpandGroup}
            isExpanded={isExpandedGroup}
        >
            {
                group.values_source.length > 0 && (
                    <RenderEnvelopes envelopes={group.values_source}
                                     groupID={group.id}
                                     isEditMode={isEditMode}
                                     expanded={expanded}
                                     onExpandToggle={toggle}
                                     moveEnvelope={moveEnvelope}
                                     getTransactionsById={getEnvTransactionsRocketById}
                                     getEnvelopeById={getEnvelopeById}
                                     currConvert={currConvert}
                                     openTransactionsPopover={openTransactionsPopover}
                                     onOpenDetails={onOpenDetails}
                    />
                )
            }
            {!!group.sub_group && group.sub_group.length > 0 && group.sub_group.map((sub) => {
                const isExpand = expandedRows?.includes(sub.id) ?? true
                return (<Group
                    id={sub.id}
                    groupName={sub.title}
                    key={`envelopes-${sub.id}`}
                    // @ts-ignore
                    getGroupTotals={getGroupTotal}
                    isSubgroup={true}
                    isEditMode={isEditMode}
                    onGroupNameClick={() => onGroupNameClick(group)}
                    // @ts-ignore
                    toggleExpand={toggleExpandGroup}
                    isExpanded={isExpand}
                >
                    {/*// @ts-ignore*/}
                    {sub.values_source.length > 0 && (
                        <RenderEnvelopes envelopes={sub.values_source}
                                         groupID={sub.id}
                                         isEditMode={isEditMode}
                                         expanded={expanded}
                                         onExpandToggle={toggle}
                                         moveEnvelope={moveEnvelope}
                                         getTransactionsById={getEnvTransactionsRocketById}
                                         getEnvelopeById={getEnvelopeById}
                                         currConvert={currConvert}
                                         openTransactionsPopover={openTransactionsPopover}
                                         onOpenDetails={onOpenDetails}
                        />
                    )
                    }
                </Group>)
            })
            }
        </Group>
    )
}, areEqual)
